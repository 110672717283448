<template>
  <div class="main-wrapper">
    <NavbarDashboard></NavbarDashboard>
    <SidebarDashboard></SidebarDashboard>
    <div class="main-content">
      <section class="section">
        <div class="d-flex ml-3 mb-2 align-items-center">
          <div class="mr-auto">
            <ul class="nav nav-pills">
              <li
                class="nav-item"
                v-if="checkAccess('setting', 'ru_item_numbering')"
              >
                <router-link to="/data-item-penomoran" class="nav-link"
                  >Item Penomoran</router-link
                >
              </li>
              <li class="nav-item">
                <router-link to="/data-item-penggajian" class="nav-link active"
                  >Item Penggajian
                </router-link>
              </li>
              <li class="nav-item" v-if="checkAccess('setting', 'sync')">
                <router-link to="/data-item-synchronize" class="nav-link"
                  >Sinkronisasi
                </router-link>
              </li>
              <li class="nav-item" v-if="checkAccess('setting', 'sync')">
                <router-link to="/data-fee-marketing" class="nav-link"
                  >Fee Marketing
                </router-link>
              </li>
              <!-- <li class="nav-item">
                                <router-link to="/data-level-project" class="nav-link">Level Project
                                </router-link>
                            </li> -->
            </ul>
          </div>

          <form class="search mr-3" @submit.prevent="onSearch">
            <input
              type="text"
              v-model="search"
              @keyup="searchCheck"
              id="searchbox"
              placeholder="Cari..."
              name="search"
            />
            <button type="submit"><i class="fa fa-search"></i></button>
          </form>
            <button class="btn-blue mr-2" v-if="enableSync" type="button" @click="getSync" :style="{
              padding: '8px 12px!important',
              borderRadius: '5px!important',
              border: '1px solid #248cf0',
              fontSize: '13px'
            }">
              <img src="/img/icon-sync.svg" alt="" /> Data Sync
            </button>
          <router-link class="btn-add" to="/data-item-penggajian/tambah"
            >+ Tambah
          </router-link>
        </div>
        <div class="card-table">
          <div class="table-responsive">
            <table
              class="table table-bordered table-striped table-hover"
              id="tablePayollItem"
            >
              <thead>
                <tr>
                  <th style="width: 40%">Nama</th>
                  <th style="width: 40%">Tipe Gaji/Tunjangan</th>
                  <th style="width: 10%">Status</th>
                  <th class="text-center" style="5%">Aksi</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(value, index) in list" :key="index">
                  <td>{{ value.nama }}</td>
                  <td>{{ value.nama_tipe }}</td>
                  <td>
                    <div class="green-bedge" v-if="value.status == 'aktif'">
                      Aktif
                    </div>
                    <div class="red-bedge" v-else>Nonaktif</div>
                  </td>
                  <td class="text-center">
                    <svg
                      v-on:click="detail(value.id)"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Lihat Detail"
                      class="edit-icon"
                      width="27"
                      height="27"
                      viewBox="0 0 31 31"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7.74935 2.58203V1.29036V2.58203ZM5.16602 5.16536H3.87435H5.16602ZM5.16602 25.832H3.87435H5.16602ZM18.0827 1.29036H7.74935V3.8737H18.0827V1.29036ZM7.74935 1.29036C6.72164 1.29036 5.73601 1.69862 5.00931 2.42533L6.836 4.25202C7.07824 4.00978 7.40678 3.8737 7.74935 3.8737V1.29036ZM5.00931 2.42533C4.28261 3.15203 3.87435 4.13765 3.87435 5.16536H6.45768C6.45768 4.82279 6.59377 4.49425 6.836 4.25202L5.00931 2.42533ZM3.87435 5.16536V25.832H6.45768V5.16536H3.87435ZM3.87435 25.832C3.87435 26.8597 4.28261 27.8454 5.00931 28.5721L6.836 26.7454C6.59377 26.5031 6.45768 26.1746 6.45768 25.832H3.87435ZM5.00931 28.5721C5.73601 29.2988 6.72163 29.707 7.74935 29.707V27.1237C7.40678 27.1237 7.07824 26.9876 6.836 26.7454L5.00931 28.5721ZM7.74935 29.707H23.2493V27.1237H7.74935V29.707ZM23.2493 29.707C24.2771 29.707 25.2627 29.2988 25.9894 28.5721L24.1627 26.7454C23.9205 26.9876 23.5919 27.1237 23.2493 27.1237V29.707ZM25.9894 28.5721C26.7161 27.8454 27.1243 26.8597 27.1243 25.832H24.541C24.541 26.1746 24.4049 26.5031 24.1627 26.7454L25.9894 28.5721ZM27.1243 25.832V5.16537H24.541V25.832H27.1243ZM23.2493 1.29036H18.0827V3.8737H23.2493V1.29036ZM27.1243 5.16537C27.1243 3.02526 25.3895 1.29036 23.2493 1.29036V3.8737C23.9627 3.8737 24.541 4.452 24.541 5.16537H27.1243Z"
                        fill="#898989"
                      />
                      <path
                        d="M21.9583 15.5H15.5"
                        stroke="#898989"
                        stroke-width="2.58333"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M10.3327 15.5H9.04102"
                        stroke="#898989"
                        stroke-width="2.58333"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M21.9583 21.957H15.5"
                        stroke="#898989"
                        stroke-width="2.58333"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M10.3327 21.957H9.04102"
                        stroke="#898989"
                        stroke-width="2.58333"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M21.9583 9.04297H18.7292H15.5"
                        stroke="#898989"
                        stroke-width="2.58333"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M10.3327 9.04297H9.68685H9.04102"
                        stroke="#898989"
                        stroke-width="2.58333"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import SidebarDashboard from "../../../components/Sidebar.vue";
import NavbarDashboard from "../../../components/Navbar.vue";
// import Select2 from 'vue3-select2-component';

import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";

import $ from "jquery";
import { get_PayrollItemList } from "../../../actions/setting";
import { checkModuleAccess, cksClient, checkApp, showLoading, showAlert } from "../../../helper";
import { post_SyncTable } from '../../../actions/integrations';

// import TableLite from 'vue3-table-lite'
export default {
  name: "dataDepartement",
  components: {
    // FooterDashboard,
    SidebarDashboard,
    NavbarDashboard,
    // Select2
    // TableLite
  },
  data() {
    return {
      enableSync: checkApp('rawuh') ? true : false,
      checkAccess: checkModuleAccess,
      isSearch: false,
      search: "",
      list: "",
      id_company: cksClient().get("_account").id_company,
      dataTable: "",
    };
  },

  beforeUnmount() {
    if (this.dataTable) {
      this.dataTable.destroy();
      this.dataTable = "";
    }
  },
  methods: {
    tooltip() {
      $('[data-toggle="tooltip"]').tooltip();
    },

    detail(id) {
      $('[data-toggle="tooltip"]').tooltip("hide");
      this.$router.push({
        name: "DetailPayrollItems",
        params: {
          id: id,
        },
      });
    },
    searchCheck: function () {
      if (!this.search && this.isSearch) {
        this.isSearch = false;
        this.getList();
      }
    },
    onSearch: function () {
      if (this.search || this.isSearch) {
        this.isSearch = true;
        this.getList();
      }
      if (!this.search && this.isSearch) {
        this.isSearch = false;
      }
    },
    async getList() {
      await get_PayrollItemList(
        {
          id_company: this.id_company,
          search: this.search,
        },
        (res) => {
          this.list = res.list;
          if (this.dataTable) {
            this.dataTable.destroy();
          }
          setTimeout(() => {
            this.dataTable = $("#tablePayollItem").DataTable({
              info: false,
              language: {
                emptyTable: "Tidak ada data",
              },
            });
            this.tooltip();
          }, 1000);
        }
      );
    },
    getSync() {
      var show = showLoading(this.$swal);
      post_SyncTable(
        {
          id_company: cksClient().get("_account").id_company,
          table: "payroll_item",
        },
        () => {
          this.getList();
          setTimeout(() => {
            show.close();
            showAlert(this.$swal, {
              title: "SUCCESS!",
              msg: "Data telah disinkronkan",
              confirmButtonText: "Tutup",
            });
          }, 500);
        },
        () => {
          show.close();
          showAlert(this.$swal, {
            title: "ERROR!",
            msg: `Terjadi kesalahan, data tidak dapat disinkronkan`,
            cancelButtonText: "Tutup",
            showCancelButton: true,
            showConfirmButton: false,
          });
        }
      );
    },
  },
  created() {
    this.getList();
  },
};
</script>

<style scoped>
button.btn-add {
  border: none !important;
}

.card-table {
  margin-top: 0px !important;
}
</style>

<style scoped src="../../../assets/css/style.css"></style>

<style scoped src="../../../assets/css/custom.css"></style>
